<template>
  <div class="supply-look-car-wrap">
    <el-tabs v-model="tabPane">
      <el-tab-pane label="未找车" name="1" />
      <el-tab-pane label="已找车" name="2" />
      <el-tab-pane label="找车记录" name="3" />

    </el-tabs>
    <div class="task-nav-wrap">
      <UnLookCar v-show="+tabPane===1" ref="unLookCar" />
      <LookCar v-show="+tabPane===2" ref="lookCar" />
      <LookCarRecords v-show="+tabPane===3" ref="lookCarRecords" />
    </div>
  </div>
</template>

<script>
import UnLookCar from './components/unLookCar';
import LookCar from './components/lookCar';
import LookCarRecords from './components/lookCarRecords';
export default {
  name: 'GoodsorderSupplylookcar',
  components: { UnLookCar, LookCar, LookCarRecords },
  data() {
    return {
      tabPane: '1',
    };
  },
  watch: {
    tabPane(val) {
      if (val === '1') {
        this.$refs.unLookCar.getList();
      }
      if (val === '2') {
        this.$refs.lookCar.getList();
      }
      if (val === '3') {
        this.$refs.lookCarRecords.getList();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
