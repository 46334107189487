<template>
  <Table
    :table-cols="tableCols"
    :table-data="tableData"
    :show-pagination="false"
  >
    <template v-slot="{ col, row }">
      <div v-if="col.prop === 'lineName'">
        <TextToolTip :text="`${row.loadAddrName||'-'}-${row.unloadAddrName||'-'}`" placement="none" />
      </div>
      <div v-if="col.prop === 'price'">
        <span class="fz_12">{{ priceTypeMap[+row.priceType] }}</span> {{ $parseNumber(row.price) }}  <span v-if="+row.priceType===1">{{ col.rulesObj[+row.priceUnit]||'-' }}</span><span v-if="+row.priceType===2">元/车</span>
      </div>
      <div v-if="col.prop === 'needTax'">
        {{ col.rulesObj[+row.needTax]||'-' }}
      </div>
      <div v-if="col.prop === 'plan'" class="plan-time">
        <div>装:<span>{{ row.loadEarlyTime|parseTime }}</span></div>
        <div>卸:<span>{{ row.unloadEarlyTime|parseTime }}</span></div>
      </div>
    </template>
  </Table>
</template>

<script>
import { parseTime } from '@/utils';
export default {
  filters: {
    parseTime,
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      tableCols: [
        { label: '货单编号', prop: 'goodsSerial', fixed: 'left', type: 'jump', evt: (data) => { this.toDetail(data); }, width: '185' },
        { label: '货单类型', prop: 'source', type: 'transition', rulesObj: this.$CONSTANT.goodsSourceMap.O, width: '80' },
        { label: '推送人', prop: 'createUsername' },
        { label: '推送时间', prop: 'createTime', type: 'filterTime', width: '165' },
        { label: '找车状态', prop: 'recordState', type: 'transition', rulesObj: { 1: '已找车', 0: '未找车' }, width: '80' },
        { label: '线路名称', type: 'customize', prop: 'lineName', width: '155' },
        { label: '产品名称', prop: 'goodsName' },
        { label: '运输数量(吨)', prop: 'total', type: 'numberTransition' },
        { label: '找车指导价', prop: 'price', type: 'customize', rulesObj: this.$CONSTANT.transportPriceUnitMap.O, width: '140' },
        { label: '是否含税', type: 'customize', prop: 'needTax', rulesObj: this.$CONSTANT.taxMap, width: '80' },
        { label: '需求车数', prop: 'truckNum', width: '80' },
        { label: '装卸计划时间', prop: 'plan', type: 'customize', width: '160' },
        { label: '操作', type: 'operation', fixed: 'right', align: 'left',
          btns: [
            {
              label: '找车',
              evt: (data) => {
                this.toDetail(data);
              },
            },
          ],
        },
      ],
      priceTypeMap: {
        1: '运价',
        2: '信息费',
      },
    };
  },
  methods: {
    toDetail({ id, recordId }) {
      this.$router.push(`/goodsOrder/supplyLookCar/detail?id=${id}&status=0&recordId=${recordId}`);
    },
  },
};

</script>
<style lang='scss' scoped>
</style>
